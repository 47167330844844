const ENVIRO = "prod";

let API_URL, API_DOMAIN, SUB_URL;

switch (ENVIRO) {
  case "prod":
    API_URL = "https://dennis-practice.net/";
    SUB_URL = "https://player.dennis-practice.net/";
    API_DOMAIN = "dennis-practice.net";
    break;
  case "test":
    API_URL = "http://127.0.0.1:8080/";
    SUB_URL = "http://127.0.0.1:8080/";
    API_DOMAIN = "127.0.0.1";
    break;
}

const GEO_GOO = "https://maps.googleapis.com/maps/api/geocode/json";
const TIMEOUT_SEC = 100;
const RES_PER_PAGE = 10;
const KEY = "76cc5abc-234e-474b-93f0-aaf8006b4ce4";
const KEY_GOO = "AIzaSyCAkIEvauUorLTDwzQ9JZ8GKGQyMgpJAD0";
const MODAL_CLOSE_SEC = 2.5;
const TIMER_CALENDAR = 1 * 60 * 1000;
const TIMER_PRACTICE = 1 * 60 * 1000;
const TIMER_REQ = 1 * 60 * 1000;

// export const PLAYER_TOP_20 = "&#128526;";
// export const PLAYER_TOP_202 = "&#129321;";
// export const PLAYER_TOP_40 = "&#128539;";
// export const PLAYER_TOP_402 = "&#128578";
// export const PLAYER_TOP_60 = "&#128528;";
// export const PLAYER_TOP_602 = "&#129488;";
// export const PLAYER_TOP_100 = "&#128563;";
// export const PLAYER_TOP_1002 = "&#129320;";
// export const PLAYER_TOP_O100 = "&#128566;";
// export const PLAYER_TOP_O1002 = "&#129319;";

const filling0 = "";

const PLAYER_TOP_20 = filling0;
const PLAYER_TOP_202 = filling0;
const PLAYER_TOP_40 = filling0;
const PLAYER_TOP_402 = filling0;
const PLAYER_TOP_60 = filling0;
const PLAYER_TOP_602 = filling0;
const PLAYER_TOP_100 = filling0;
const PLAYER_TOP_1002 = filling0;
const PLAYER_TOP_O100 = filling0;
const PLAYER_TOP_O1002 = filling0;
const LEFT_HANDED_HITTER = "&#128072;";
const RIGHT_HANDED_HITTER = "&#128073;";
const COACH = "&#129312;";
const COACH2 = "&#129304;";
const COACH3 = "&#129299";
const FREEPLAYER = "&#128125;";

module.exports = {
  TIMER_CALENDAR,
  TIMER_PRACTICE,
  TIMER_REQ,
  PLAYER_TOP_20,
  PLAYER_TOP_202,
  PLAYER_TOP_40,
  PLAYER_TOP_402,
  PLAYER_TOP_60,
  PLAYER_TOP_602,
  PLAYER_TOP_100,
  PLAYER_TOP_1002,
  PLAYER_TOP_O100,
  PLAYER_TOP_O1002,
  LEFT_HANDED_HITTER,
  RIGHT_HANDED_HITTER,
  COACH,
  COACH2,
  COACH3,
  FREEPLAYER,
  API_URL,
  API_DOMAIN,
  SUB_URL,
  GEO_GOO,
  TIMEOUT_SEC,
  RES_PER_PAGE,
  KEY,
  KEY_GOO,
  MODAL_CLOSE_SEC,
};

//http://api.sportradar.us/tennis/trial/v3/en/rankings.json?api_key=5uh7cc6asfh6y39p6fmvwvub
//http://api.sportradar.us/tennis/trial/v3/en/rankings.json?api_key=xzf5kbnmzj5whzwqevf3dvmb
// http://api.sportradar.us/tennis/trial/v3/en/double_competitors_rankings.json?api_key=xzf5kbnmzj5whzwqevf3dvmb

// geocoding google:
// //https://maps.googleapis.com/maps/api/geocode/json?
// address=1600+
// Amphitheatre+Parkway,+Mountain+View,+CA&
// key=AIzaSyD9hOKYHCatMb6pEP_osl9j78N4O39B85I
// left hand 128072
// right hand 128073
// coach 129304
